import React from 'react';
import { Link } from "gatsby"
import Products from "../../components/peaksuiteProducts"

const PeaksuitePivotTableSection = ({handleSubmit}) => <div className="page-content pt-0" style={{ background: "#f8f8f8" }}>
          <section className="component pt-4 pb-0">
            <div className="container">
              <div className="row mb-5">
                <div className="col-md-12 peakSuite-product">
                  <div className="row justify-content-md-center">
                    <div className="col-md-8 col-sm-12 align-self-center mt-4">
                      <p>
                        <span className="basecamp-color">
                          peakSUITE | Pivot Table{" "}
                        </span>
                        Comparing data sets has never been easier or more
                        customizable with Pivot Table. Rather than build out an
                        extensive table in your database, Pivot Table provides a
                        fully custom code page that can query and organize data
                        into a true pivot format.
                      </p>
                      <p>
                        Compare fields from any table in your database to use as
                        filters. Automatically calculate for incoming data. And
                        handle everything from simple counts to complex
                        variables all within Pivot Table.
                      </p>
                    </div>
                  </div>
                  {/* <div className="list-wrap d-block">
                    <p>What can this application do?</p>
                    <ul className="list-unstyled basecamp">
                      <li>Reporting and notifications for managers</li>
                      <li>Track tasks and who they are assigned to</li>
                      <li>
                        Easily see which tasks are complete and which tasks are
                        outstanding
                      </li>
                      <li>
                        Allow employees to flag tasks that require assistance to
                        complete, or are blocked
                      </li>
                      <li>
                        Record meeting notes in one place for easy reference
                      </li>
                      <li>
                        Implement daily summary notes for employees to document
                        their productivity
                      </li>
                      <li>
                        Maintain a list of your employees, their contact
                        information, and the company inventory they are using at
                        their home office
                      </li>
                      <li>
                        Provide employees with a ‘who to call’ list so they will
                        know who to reach out to if they have certain issues
                      </li>
                      <li>
                        Keep employees up to date with company news and
                        announcements with peakSUITE | Echo, a tool for company
                        announcements built right into their dashboard
                      </li>
                      <li>
                        Maintain a list of your clients and their contact
                        information if desired
                      </li>
                      <li>Store documents for easy reference</li>
                    </ul>
                  </div> */}

                  {/* <p>
                    Managers see a real time overview of work in progress,
                    complete, and outstanding on their dashboard. Stay connected
                    and understand what your employees are working on and where
                    they need help. Fully customizable to meet your
                    company&#39;s specific needs in this rapidly changing time.
                  </p> */}
                  <div className="text-center mt-5">
                    <Link
                      to="/contact/"
                      onClick={handleSubmit}
                      className="btn btn-primary btn-arrow echo-bg"
                    >
                      Contact us
                    </Link>
                  </div>
                </div>
              </div>
              <div className="row peaksuite-products mt-5">
                <div className="col-lg-2 col-md-3 col-12 align-self-center  prod-order-2">
                  {" "}
                  <div className="return-btn-basecamp">
                    <Link
                      to="/peaksuite/"
                      className="btn btn-primary-outline px-3"
                      style={{
                        color: "#224a9a",
                        border: "1px solid #224a9a",
                        fontSize: "18px",
                      }}
                    >
                      <i className="fa fa-caret-left mr-2" />
                      Return
                    </Link>
                  </div>
                </div>
                <div className="col-lg-10 col-md-9 col-12 prod-order-1">
                  <Products />
                </div>
              </div>
            </div>
          </section>
        </div>;

export default PeaksuitePivotTableSection;
